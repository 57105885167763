import "core-js/modules/es.array.push.js";
import { getRolePage } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0
      },
      exportIds: '',
      checkMuneSer: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.phone = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getRolePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/user/roleEdit",
        query: {
          Info: data
        }
      });
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    closeDiv() {
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    handleSelectionChange(e) {
      //多选用户
      let exportIds = '';
      for (let i in e) {
        let id = e[i].id;
        if (i == 0) {
          exportIds = id;
        } else {
          exportIds = exportIds + "," + id;
        }
      }
      this.exportIds = exportIds;
    }
  }
};